@tailwind base;
@tailwind components;
@tailwind utilities;

.desaturate {
  filter: grayscale(100%);
}

.first-letter {
  font-size: 35px;
  line-height: 70px;
}

.text-primary {
  color: rgb(88, 198, 5);
}

.bg-primary {
  background-color: rgba(88, 198, 5, 0.1);
}

.bg-primary-active {
  background-color: rgb(88, 198, 5);
}

body {
  /*
  font-family: 'circular', sans-serif;
   Use the font in your styles */
}

html,
body {
  height: 100%;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.flashing {
  animation: flash 1s infinite;
}
